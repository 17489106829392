import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"


import Layout from "../components/layout"
import Seo from "../components/seo"
import Showcase from "../components/showcase"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Home" />

      <header className="global-header">
        <h2><Link to="/">Farshad Jafarzadeh </Link></h2>
        <p className="subHeader">
          PhD researcher student at CHOSE - Center for Hybrid and Organic Solar Energy
        </p>
      </header>
      <Showcase/>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`
